<template lang="pug">
	b-dropdown.dropdown.b3.dropdown-general.animated(v-if="list" :id="`dropdown-general`" :text='selected' :right="right" :class="[size, selectedColor, {'not-chosen' : notChosen && selected == list[0].title}, icon, {'is-error' : error}]" menu-class='p-0 m-0 b3' :disabled='disabled' @show="onDropdownShow" @hide="onDropdownHide")
		template(#button-content)
			span(v-html="selected")
		b-dropdown-item(v-for='(item, i) in list' :key='i' @click="selected = String(item.title), $emit('onChange', notChosen ? selected : (fullItem ? item : item.id))" )
			span(v-if="item.title" v-html="item.title")
			svg.ml-3(v-if="selected == item.title" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
</template>

<script>
export default {
    name: 'DropdownGeneral',
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        size: {
            default: '',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        notChosen: {
            default: false,
            type: Boolean,
        },
        fullItem: {
            default: false,
            type: Boolean,
        },
        right: {
            default: false,
            type: Boolean,
        },
        selecteDefault: {
            default: 0,
            type: [Number, String],
        },
        icon: {
            default: '',
            type: String,
        },
        error: {
            default: false,
            type: Boolean,
        },
        element: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        selected: '',
        selectedColor: '',
    }),
    created() {
        this.defaultSelected();
    },
    mounted() {
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if (bvEvent.componentId == `dropdown-general`) {
                this.$emit('dropdownShow');
            }
        });
    },
    methods: {
        defaultSelected() {
            this.selected = this.list[this.selecteDefault].title.toString();
            this.selectedColor = this.list[this.selecteDefault].color;
        },
        onDropdownShow() {
            this.$emit('onDropdownShow', true, this.element);
        },
        onDropdownHide() {
            this.$emit('onDropdownHide', false, this.element);
        },
    },
    watch: {
        selecteDefault(v) {
            this.defaultSelected();
        },
        selected(newValue) {
            const selectedItem = this.list.find(item => item.title === newValue);
            if (selectedItem) {
                this.selectedColor = selectedItem.color;
            }
        },
    },
};
</script>
