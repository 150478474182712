<template lang="pug">
	.d-flex.flex-column.flex-md-row.table-profile
		.card-basic.mb-3.mb-md-0.mr-md-3.table-profile__left
			div(v-if='me')
				p.b1.mb-0 {{ $t('h1.basic') }}
				.table-profile__card
					span {{ $t('forms.placeholder.fio') }}
					span.text-break {{ me.fio }}
				.table-profile__card
					span {{ $t('forms.placeholder.account') }}
					span {{ me.id }}
				.table-profile__card
					span {{ $t('user.register') }}
					span.text-break {{ me.registeredAt | dateDDMMMYYYYTZ }}
				.table-profile__card
					span {{ $t('forms.tgId') }}
					span.text-break {{ me.tgId }}
				.table-profile__card
					span {{ $t('forms.tgUsername') }}
					span
						a(:href='`https://t.me/${me.tgUsername}`' target='_blank') @{{ me.tgUsername }}
				.table-profile__card
					span {{ $t('forms.email') }}
					span.text-break {{ me.emailPersonal }}
				.table-profile__card
					span {{ $t('forms.phone') }}
					span.text-break {{ me.phone }}
				.table-profile__card(:class="{'border-none': !ooo && !ooo.id}")
					span {{ $t('forms.placeholder.country') }}
					span.text-break(v-html="country")

			div(v-if='ooo && ooo.id')
				p.b1.mb-0.mt-4 {{ $t('forms.placeholder.organization') }}
				.table-profile__card
					span {{ $t('forms.placeholder.fullName') }}
					span.text-break {{ ooo.fullName }}
				.table-profile__card
					span {{ $t('forms.placeholder.companyShortSuper') }}
					span.text-break {{ ooo.shortName }}
				.table-profile__card
					span {{ $t('forms.placeholder.inn') }}
					span.text-break {{ ooo.inn }}
				.table-profile__card(v-if='ooo.kpp != "000000000"')
					span {{ $t('forms.placeholder.kpp') }}
					span.text-break {{ ooo.kpp }}
				.table-profile__card
					span {{ $t('forms.placeholder.ogrn') }}
					span.text-break {{ ooo.ogrn }}
				.table-profile__card
					span(v-if='ooo.type.value == "INDIVIDUAL"') {{ $t('forms.placeholder.ownerAddress') }}
					span(v-else) {{ $t('forms.placeholder.companyAddress') }}
					span.text-break {{ ooo.addressShown ? ooo.addressShown : (ooo.addressesStreet ? ooo.addressesStreet : ooo.adress) }}
				.table-profile__card
					span {{ $t('forms.city') }}
					span.text-break {{ ooo.addressesCity }}
				.table-profile__card
					span {{ $t('forms.placeholder.zip') }}
					span.text-break {{ ooo.addressesZip }}
				.table-profile__card
					span {{ $t('forms.email') }}
					span.text-break {{ ooo.email }}

			div(v-if='ooo && ooo.id')
				p.b1.mb-0.mt-4 {{ $t('finance.accountTypes[1].requisitesOwner') }}
				.table-profile__card
					span {{ $t('forms.placeholder.fio') }}
					span.text-break {{ ooo.lastName }} {{ ooo.firstName }} {{ ooo.middleName }}
				.table-profile__card
					span {{ $t('forms.placeholder.birthday') }}
					span.text-break {{ ooo.birthDate | dateDDMMMMYYYY }}
				.table-profile__card
					span {{ $t('forms.phone') }}
					span.text-break {{ ooo.phone }}

			div(v-if='ooo && ooo.id')
				p.b1.mb-0.mt-4 {{ $t('finance.accountTypes[1].requisitesCompany') }}
				.table-profile__card
					span {{ $t('forms.placeholder.checkingAccount') }}
					span.text-break {{ ooo.bankAccountNumber }}
				.table-profile__card
					span {{ $t('forms.placeholder.nameBank') }}
					span.text-break {{ ooo.bankAccountName }}
				.table-profile__card
					span {{ $t('forms.placeholder.bik') }}
					span.text-break {{ ooo.bankAccountBik }}
				.border-none.table-profile__card
					span {{ $t('finance.corAccount2') }}
					span.text-break {{ ooo.bankCorrAccountNumber }}

		.card-basic.table-profile__right
			p.b3.mb-4.position-relative.table-profile__warning(v-html="$t('support.editOnlySupport')")

			a.d-block.mb-3.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
				pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
			pw-btn-mail
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';
import { isoMethods } from '@/constants/countries';
import { mapActions, mapGetters } from 'vuex';
import { STATUS_REMOVED, STATUS_ACTIVE } from '@/models/model';

export default {
    name: 'TableProfile',
    components: {
        PwBtn,
        PwBtnMail,
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            accountList: 'finance/accounts',
        }),
        country() {
            return this.me && this.me.country ? isoMethods.getName(this.me.country) : '';
        },
        ooo() {
            let i = _.find(this.accountList, a => {
                if (
                    ((a.isOOO() && a.isActive()) || (a.isIP() && a.isActive()) || a.isStripe()) &&
                    a.hasRequisite() &&
                    a.isActive()
                )
                    return a;
            });

            return i ? i.shop : {};
        },
    },
    async created() {
        this.$emit('onCreated', true);

        if (!this.me || this.me?.accounts?.length == 0) {
            await this.getAccounts({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 });
            await this.getAccountsFinance({ status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000 }).then(
                list => {
                    _.each(this.me.accounts, a => {
                        _.each(list, l => {
                            if (a.id == l.id) {
                                a.setFinance(l);
                            }
                        });
                    });

                    this.$emit('onLoad', false);
                }
            );

            return;
        }

        this.$emit('onLoad', false);
    },
    methods: {
        ...mapActions({
            getAccounts: 'finance/accounts',
            getAccountsFinance: 'users/finance',
        }),
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.table-profile {
    &__left {
        width: 100%;
        max-width: 565px;

        @include respond-below(sm) {
            max-width: 100%;
        }
    }

    &__right {
        width: 100%;
        max-width: 350px;

        @include respond-below(sm) {
            max-width: 100%;
        }
    }

    &__card {
        display: flex;
        align-items: center;
        min-height: 46px;
        padding: 12px 0;
        border-bottom: 1px solid var(--grayscale-border);

        @include respond-below(sm) {
            flex-direction: column;
            align-items: start;
            padding: 15px 0;
        }

        span {
            &:first-child {
                width: 49%;
                font-size: 14px;
                line-height: 22px;
                color: var(--grey-text-color);
                margin-right: 6px;

                @include respond-below(sm) {
                    width: 100%;
                    margin: 0 0 10px;
                }
            }

            &:last-child {
                width: 51%;
                font-size: 14px;
                line-height: 22px;
                border-bottom: none;

                @include respond-below(sm) {
                    width: 100%;
                }
            }
        }
    }

    &__warning {
        padding: 16px 16px 16px 51px;
        background-color: var(--brand-bg-warning);
        border-radius: var(--border-radius-rounded);

        &::before {
            content: '';
            position: absolute;
            top: 16px;
            left: 16px;
            width: 24px;
            height: 24px;
            background: url('/assets/img/icons/info-square.svg');
            background-repeat: no-repeat;
        }

        @include respond-below(sm) {
            padding: 16px 21px 16px 51px;
        }
    }

    .border-none {
        border-bottom: none;
    }
}
</style>
